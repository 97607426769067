import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Contact Me`}</h2>
    <p>{`You can reach out to me on `}<a parentName="p" {...{
        "href": "https://www.facebook.com/sushant.tandukar.5/"
      }}>{`Facebook`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/sushant-tandukar-090614169/"
      }}>{`Linkedin`}</a>{` or `}<a parentName="p" {...{
        "href": "https://www.instagram.com/__s_u_s_h_a_n_t___/"
      }}>{`Instagram`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      